import dayJS, {type Dayjs} from 'dayjs';
import localeNl from 'dayjs/locale/nl';
import customFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

// Configure dayjs
const dayjs = dayJS;
dayjs.extend(customFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.locale(localeNl);

type dayjsDateType = string | number | Date | Dayjs;

const DateHelper = {

	format: 'YYYY-MM-DD',

	/**
	 * Retrieve a formatted date string for displaying purposes
	 * @param dateObject
	 * @param format
	 * @param providedFormat (Optional)
	 */
	getFormattedDate(dateObject: dayjsDateType, format: string, providedFormat?: string | null) {
		return providedFormat
			? this.toDate(dateObject, providedFormat).format(format)
			: this.toDate(dateObject).format(format);
	},

	addMonths(dateObject: dayjsDateType, months: number) {
		return this.toDate(dateObject).add(months, 'month');
	},

	addYears(dateObject: dayjsDateType, years: number) {
		return this.toDate(dateObject).add(years, 'year');
	},

	/**
	 * Instantiate a DayJs date object
	 * @param dateObject
	 * @param providedFormat - (Optional) When provided the dayjs will have a strict check (impossible dates like 30-02-2022 won't be corrected)
	 */
	toDate(dateObject: dayjsDateType, providedFormat?: string | null) {
		return providedFormat ? dayjs(dateObject, providedFormat, true) : dayjs(dateObject);
	}

};

export const getCurrentDateInYYYYMMDD = () => {
	return new Date().toLocaleDateString('nl-NL', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	}).split('-').reverse().join('-');
};

export default DateHelper;
